import contextify from "@/components/contextify";

export default function(config, onConfirm) {
  const opts = {
    ...config,
    onConfirm,
    inverse: { y: false, x: true },
    style: { overflow: "visible" }
  };
  return contextify(
    () => import(/* webpackChunkName: "spreadsheet" */ "./src/Edit"),
    opts
  );
}
