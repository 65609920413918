import Loading from "./src/Loading";
import LoadingError from "./src/LoadingError";

function getResetComponent(component, width) {
  let resetComponent = component;
  if (width) {
    resetComponent = {
      ...component,
      data() {
        return {
          paneWidth: width
        };
      }
    };
  }
  return resetComponent;
}

export default function(width) {
  const ResetLoading = getResetComponent(Loading, width);
  const ResetLoadingError = getResetComponent(LoadingError, width);
  return { Loading: ResetLoading, LoadingError: ResetLoadingError };
}
