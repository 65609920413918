import Actions from "@/constant/datasheet-actions";
import clone from "lodash/cloneDeep";

import Column from "@/model/column";
import cloneDeep from "lodash/cloneDeep";
import { isEqual } from "@/utils/lib";

import workspace from "@/views/datamodel/models/workspace";

import showContextSelectColumn from "@/views/datamodel/components/context-select-column";
import showContextFilter from "@/views/datamodel/components/context-filter";
import showContextSort from "@/views/datamodel/components/context-sort";
import showContextInsertColumn from "@/views/datamodel/components/context-insert-fncolumn";
import showContextGroup from "@/views/datamodel/components/context-group";
import showContextTopN from "@/views/datamodel/components/context-topn";
import showJoinEditor from "@/views/datamodel/components/datasheet-join";
import showSQLEditor from "@/views/datamodel/components/datasheet-sql";
import showContextCaseWhen from "@/views/datamodel/components/context-case-when";

import Condition from "@/views/datamodel/models/condition";

export function undo() {
  if (workspace.currentDatasheet.undoable) {
    const action = Actions.undo();
    workspace.currentDatasheet.send(action);
  }
}
export function redo() {
  if (workspace.currentDatasheet.redoable) {
    const action = Actions.redo();
    workspace.currentDatasheet.send(action);
  }
}

export async function selectColumns(evt) {
  const datasheet = workspace.currentDatasheet;
  /**
   * @type {Column[]}
   */
  const currentColumns = datasheet.columns || [];
  let fieldList = datasheet.fieldList.map(item => {
    const field = Column.from(item);
    field.visible = currentColumns.some(
      column => column.columnId === item.columnId
    );
    return field;
  });
  const keepState = cloneDeep(fieldList);
  const contextOptions = { source: evt.target, columns: fieldList };
  await showContextSelectColumn(contextOptions).wait();
  // 操作前后无变化，不用发请求
  if (isEqual(keepState, fieldList)) {
    return;
  }
  const action = { columns: fieldList.filter(item => item.visible) };
  datasheet.send(Actions.select(action));
}

export async function setFilters(evt) {
  const datasheet = workspace.currentDatasheet;
  const filter = cloneDeep(datasheet.filter);
  const selectedColumnMap = datasheet.columns.reduce((map, item) => {
    map[item.columnId] = true;
    return map;
  }, {});
  const columns = [].concat(
    datasheet.columns || [],
    // 未选进工作表的列放在尾部
    datasheet.fieldList.filter(item => !selectedColumnMap[item.columnId])
  );
  const keepState = cloneDeep(filter);
  const contextOptions = { columns, filter, source: evt.target };
  await showContextFilter(contextOptions).wait();
  if (isEqual(keepState, filter)) {
    return;
  }
  datasheet.send(Actions.filter(filter));
}

export async function setSort(evt) {
  const datasheet = workspace.currentDatasheet;
  const sort = cloneDeep(datasheet.sort);
  const keepState = cloneDeep(sort);
  const contextOptions = {
    sort,
    source: evt.target,
    columns: datasheet.columns
  };
  await showContextSort(contextOptions).wait();
  if (isEqual(keepState, sort)) {
    return;
  }
  datasheet.send(Actions.sort(sort));
}

export async function addColumn(evt) {
  showContextInsertColumn({ source: evt.target }, data => {
    const action = Actions.insert({
      columnName: data.columnName,
      columnOptions: {
        formula: data.formula
      }
    });
    workspace.currentDatasheet.send(action);
  });
}

export async function changeCondition(evt) {
  const column = workspace.currentDatasheet.columns;
  const transform = column.map(item => {
    return new Condition.CaseWhen(item.transform);
  });
  const defaultState = [];
  transform.forEach((item, index) => {
    if (item.conditions.length) {
      item.conditions.forEach(items => {
        defaultState.push({ ...column[index], conditions: [items] });
      });
    }
  });

  const keepState = clone(defaultState);

  await showContextCaseWhen({
    column: column,
    source: evt.target,
    transform: defaultState
  }).wait();
  let targetArray = new Map();
  let preArray = new Map();

  defaultState.forEach(item => {
    if (targetArray.has(item.columnId)) {
      let temp = targetArray.get(item.columnId);
      temp.conditions = [...temp.conditions, ...item.conditions];
      targetArray.set(item.columnId, temp);
    } else {
      targetArray.set(item.columnId, item);
    }
  });

  keepState.forEach(item => {
    if (preArray.has(item.columnId)) {
      let temp = preArray.get(item.columnId);
      temp.conditions = [...temp.conditions, ...item.conditions];
      preArray.set(item.columnId, temp);
    } else {
      preArray.set(item.columnId, item);
    }
  });

  if (isEqual(preArray, targetArray)) {
    return;
  }

  preArray = [...preArray.values()];
  targetArray = [...targetArray.values()];

  let changeArray = []; // 改变的项
  let deleteArray = []; // 删除的项
  let addArray = []; // 增加的项

  preArray.forEach(item => {
    let flag = false;
    targetArray.forEach((item2, index2) => {
      if (item2.columnId == item.columnId) {
        flag = true;
        if (!isEqual(item2, item)) {
          changeArray.push(item2);
        }
      }
      if (index2 == targetArray.length - 1 && flag == false) {
        deleteArray.push(item);
      }
    });
  });

  if (preArray.length) {
    targetArray.forEach(item => {
      let flag = false;
      preArray.forEach((item2, index2) => {
        if (item.columnId == item2.columnId) {
          flag = true;
        }
        if (index2 == preArray.length - 1 && flag == false) {
          addArray.push(item);
        }
      });
    });
  } else {
    addArray = [...targetArray];
  }

  if (deleteArray.length) {
    deleteArray.forEach((item, index) => {
      deleteArray[index].conditions = [];
    });
  }
  let resolveArray = [...changeArray, ...deleteArray, ...addArray];
  resolveArray.forEach(item => {
    item.transform = { conditions: item.conditions, type: null };
    delete item.conditions;
    const action = Actions.casewhen(item);
    workspace.currentDatasheet.send(action);
  });
}

export function changeConditionNum() {
  const column = workspace.currentDatasheet.columns;
  const transform = column.map(item => {
    return new Condition.CaseWhen(item.transform);
  });
  let conditionNum = 0;
  transform.forEach(item => {
    if (item.conditions.length) {
      conditionNum = item.conditions.length + conditionNum;
    }
  });
  return conditionNum;
}

export async function setGroup(evt) {
  const datasheet = workspace.currentDatasheet;
  const group = cloneDeep(datasheet.group);
  const keepState = cloneDeep(group);
  await showContextGroup({
    group,
    source: evt.target,
    columns: datasheet.columns
  }).wait();
  if (isEqual(keepState, group)) {
    return;
  }
  datasheet.send(Actions.group(group));
}

export async function setTopN(evt) {
  const datasheet = workspace.currentDatasheet;
  const topn = Object.assign({ start: null, end: null }, datasheet.topn);
  const keepState = cloneDeep(topn);
  await showContextTopN({ topn, source: evt.target }).wait();
  if (isEqual(keepState, topn)) {
    return;
  }
  datasheet.send(Actions.topn(topn));
}

export async function queryData() {
  const datasheet = workspace.currentDatasheet;
  const query = datasheet.query || {};
  const sql = await showSQLEditor({ sql: query.sql });
  const action = Actions.query({ sql, enable: !!sql });
  datasheet.send(action);
}

export async function join() {
  const datasheet = workspace.currentDatasheet;
  const joins = await showJoinEditor({ joins: datasheet.joins || [] });
  const action = Actions.join({ joins });
  datasheet.send(action);
}
