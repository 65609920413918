<template>
  <div
    class="ndl-padding-sm"
    style="min-height: 140px; max-height: 80vh; overflow: auto"
  >
    <div class="ndl-margin-bottom">视图({{ sheets.length }})</div>
    <component
      :is="dragComponent"
      v-model="sheets"
      group="datasheet"
      :animation="200"
      tag="ul"
      handle=".item-drag"
      class="list"
    >
      <ListItem
        v-for="item in sheets"
        :key="item.nodeId"
        :node="item"
        :is-active="datasheetId === item.nodeId"
        @select="onSelect(item)"
      />
    </component>
  </div>
</template>

<script>
import router from "@/router";
import workspace from "@/views/datamodel/models/workspace";
import Draggable from "vuedraggable";
import ListItem from "./ContextItem";

export default {
  inheritAttrs: false,
  components: { Draggable, ListItem },
  props: {
    workspaceId: [String, Number],
    datasheetId: [String, Number],
    // contextify 中渲染的组件默认传入 hideContext 方法以便在内部关闭 context
    hideContext: Function
  },
  computed: {
    sheets: {
      get() {
        const node = workspace.getNodeById(this.workspaceId);
        const children = node ? node.children : [];
        return children.filter(item => item.isDatasheet);
      },
      set(children) {
        const node = workspace.getNodeById(this.workspaceId);
        if (node && node.children) {
          node.updateChildrenPosition(children);
        }
      }
    },
    dragComponent() {
      const node = workspace.getNodeById(this.workspaceId);
      const permissions = node.permissions || { manageable: false };
      return permissions.manageable ? "Draggable" : "div";
    }
  },
  methods: {
    onSelect(sheet) {
      const params = {
        workspaceId: this.workspaceId,
        datasheetId: sheet.nodeId
      };
      // ⚠️ contextify 里面渲染的组件不能访问 this.$router 选项
      router.replace({ name: "datamodel-workspace", params });
      this.hideContext();
    }
  }
};
</script>
