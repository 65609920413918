<template>
  <Pane :style="{ width: paneWidth }">
    <a-result
      class="error"
      status="500"
      title="ERROR"
      sub-title="The Kinship component fails to be loaded"
    />
  </Pane>
</template>

<script>
import Pane from "../../pane-container";
import { PANE_WIDTH } from "./constant";
export default {
  components: { Pane },
  data() {
    return { paneWidth: PANE_WIDTH };
  }
};
</script>

<style lang="less" scoped>
.error {
  padding: 100px 0;
}
</style>
