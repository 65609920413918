<template>
  <div class="scrollable" @wheel.prevent="onMouseWheel">
    <span v-show="showArrowLeft" class="btn-before" @click="onScrollLeft()">
      <i class="ndl-icon-chevron-left"></i>
    </span>
    <span v-show="showArrowRight" class="btn-after" @click="onScrollRight()">
      <i class="ndl-icon-chevron-right"></i>
    </span>
    <div
      ref="wrapper"
      :class="wrapperClassComputed"
      :style="wrapperStyles"
      @transitionend.self="onTransitionEnd"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import MixinDragdrop from "./mixin-dragdrop.js";

const IS_MACOS = navigator.userAgent.indexOf("Macintosh") >= 0;
const DEBOUNCE_WAIT = 60;

export default {
  mixins: [MixinDragdrop],
  props: {
    wrapperClass: null
  },
  provide() {
    return { scrollable: this };
  },
  data() {
    return {
      containerWidth: 0,
      wrapperWidth: 0,
      scrollLeft: 0,
      useTransition: !IS_MACOS
    };
  },
  computed: {
    wrapperClassComputed() {
      // windows 环境下加上 transition
      return [
        this.useTransition ? "transition" : "",
        "wrapper",
        this.wrapperClass
      ];
    },
    wrapperStyles() {
      return {
        transform: `translateX(${-this.scrollLeft}px)`
      };
    },
    showArrowLeft() {
      return this.scrollLeft > 0;
    },
    showArrowRight() {
      const { scrollLeft, containerWidth, wrapperWidth } = this;
      return scrollLeft < wrapperWidth - containerWidth;
    }
  },
  mounted() {
    this.onResize();
  },
  updated() {
    this.onResize();
  },
  methods: {
    onResize: debounce(function() {
      this.containerWidth = this.$el.offsetWidth;
      this.wrapperWidth = this.$refs.wrapper.scrollWidth;
      // resize 后重新计算滚动位置
      this.scroll(0);
    }, DEBOUNCE_WAIT),
    onMouseWheel: (() => {
      if (IS_MACOS) {
        return function(evt) {
          this.scroll(evt.deltaX);
        };
      }
      return function(evt) {
        this.scroll(evt.deltaY > 0 ? 20 : -20);
      };
    })(),
    /**
     * @param {number} offset 滚动量
     */
    scroll(offset = 0) {
      const maxScrollLeft = this.wrapperWidth - this.containerWidth;
      const scrollLeft = Math.min(this.scrollLeft + offset, maxScrollLeft);
      this.scrollLeft = Math.max(scrollLeft, 0);
    },
    onScrollLeft() {
      this.useTransition = true;
      this.scroll(-200);
    },
    onScrollRight() {
      this.useTransition = true;
      this.scroll(200);
    },
    onTransitionEnd() {
      this.useTransition = !IS_MACOS;
    },
    /**
     * 滚动以显示子元素
     * @param {Node} elm
     */
    async scrollIntoView(elm) {
      const root = this.$el;
      const isChild = root && elm instanceof Node && root.contains(elm);
      if (!isChild) {
        return;
      }
      // 等待 onResize() 调度完成再滚动
      await new Promise(resolve => setTimeout(resolve, DEBOUNCE_WAIT * 2));
      const elmBounding = elm.getBoundingClientRect();
      const rootBounding = root.getBoundingClientRect();
      // ± 40px 是因为左右两边存在按钮，调整按钮宽度时需要适当调整此处
      if (elmBounding.left < rootBounding.left) {
        this.useTransition = true;
        this.scroll(elmBounding.left - rootBounding.left - 40);
      } else if (elmBounding.right > rootBounding.right) {
        this.useTransition = true;
        this.scroll(elmBounding.right - rootBounding.right + 40);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.scrollable {
  position: relative;
  overflow: hidden;

  .btn-before,
  .btn-after {
    position: absolute;
    background: #fff;
    padding: 4px 8px;
    z-index: 1;
    top: 0;
    bottom: 0;
    border-bottom: solid 1px #ebebeb;
    cursor: pointer;
  }
  .btn-before {
    left: 0;
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.3);
  }
  .btn-after {
    right: 0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3);
  }

  .wrapper {
    display: flex;
    align-items: center;
    transform: translateX(0);
  }
  .wrapper.transition {
    transition: transform 0.2s linear;
  }
}
</style>
