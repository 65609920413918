<template>
  <div class="toolbar">
    <Button
      icon="ndl-icon-corner-up-left"
      label="撤销"
      :show-label="false"
      :disabled="!currentDatasheet.undoable"
      @click="undo()"
    />
    <Button
      icon="ndl-icon-corner-up-right"
      label="重做"
      :show-label="false"
      :disabled="!currentDatasheet.redoable"
      @click="redo()"
    />
    <span class="divider"></span>
    <Button
      icon="ndl-icon-layers"
      label="选择列"
      :disabled="enableSQL"
      :show-label="showButtonLabel"
      @click="selectColumns"
    />
    <Button
      icon="ndl-icon-filter"
      :label="`过滤${normalizeLength(currentDatasheet.filter)}`"
      :disabled="enableSQL"
      :active="!!normalizeLength(currentDatasheet.filter)"
      :show-label="showButtonLabel"
      @click="setFilters"
    />
    <Button
      icon="ndl-icon-sort-desc-normal"
      :label="`排序${normalizeLength(currentDatasheet.sort)}`"
      :disabled="enableSQL"
      :active="!!normalizeLength(currentDatasheet.sort)"
      :show-label="showButtonLabel"
      @click="setSort"
    />
    <Button
      icon="ndl-icon-math-function"
      label="公式列"
      :disabled="enableSQL"
      :show-label="showButtonLabel"
      @click="addColumn"
    />
    <Button
      icon="ndl-icon-git-branch"
      :label="
        `条件转换${
          changeConditionNum() > 0 ? '(' + changeConditionNum() + ')' : ''
        }`
      "
      :disabled="enableSQL"
      :active="!!changeConditionNum()"
      :show-label="showButtonLabel"
      @click="changeCondition"
    />
    <span class="divider"></span>
    <Button
      icon="ndl-icon-grid-add"
      :label="`分组${normalizeLength(currentDatasheet.group)}`"
      :disabled="enableSQL"
      :active="!!normalizeLength(currentDatasheet.group)"
      :show-label="showButtonLabel"
      @click="setGroup"
    />
    <Button
      icon="ndl-icon-sort-desc-grid"
      label="Top N"
      :active="hasTonN"
      :disabled="enableSQL"
      :show-label="showButtonLabel"
      @click="setTopN"
    />
    <Button
      icon="ndl-icon-repeat"
      label="关联"
      :active="hasJoin"
      :disabled="enableSQL"
      :show-label="showButtonLabel"
      @click="join()"
    />
    <span style="flex: 1 1 0"></span>
    <Button
      icon="ndl-icon-code-slash"
      label="SQL"
      :active="enableSQL"
      :show-label="showButtonLabel"
      @click="queryData()"
    />
  </div>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import Button from "./Button";
import * as actions from "./actions";

export default {
  components: { Button },
  data() {
    return { showButtonLabel: true };
  },
  computed: {
    currentDatasheet() {
      return workspace.currentDatasheet;
    },
    hasTonN() {
      const topn = workspace.currentDatasheet.topn || {};
      return !!(topn.start || topn.end);
    },
    hasJoin() {
      const joins = workspace.currentDatasheet.joins || [];
      return !!joins.length;
    },
    enableSQL() {
      /**
       * @type {{ enable: boolean, sql: string }}
       */
      const query = workspace.currentDatasheet.query || {};
      return !!query.enable;
    }
  },
  mounted() {
    this.updateButtonLabelVisibility();
    const observer = new ResizeObserver(this.updateButtonLabelVisibility);
    observer.observe(this.$el);
  },
  methods: {
    ...actions,
    // 检测 toolbar 宽度是否足够显示按钮 label，请通过浏览器调试器获取最佳值
    updateButtonLabelVisibility() {
      this.showButtonLabel = this.$el.offsetWidth >= 740;
    },
    /**
     * @typedef {{
     *    conditions: object[],
     *    type?: string,
     * }} Condition
     *
     * @param {Condition} cond
     * @returns {string}
     */
    normalizeLength(cond) {
      const conditions = (cond && cond.conditions) || [];
      return conditions.length ? `(${conditions.length})` : "";
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.toolbar {
  display: flex;
  align-items: center;
  margin: 20px @workspace-edge 14px;

  .divider {
    width: 30px;
    flex-shrink: 0;
  }
}
</style>
