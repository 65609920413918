import load from "../pane-loading";

const { Loading, LoadingError } = load("430px");
export default () => ({
  // eslint-disable-next-line prettier/prettier
  component: import(/* webpackChunkName: "pane-kinship" */ "./src/PaneSchedule.vue"),
  loading: Loading,
  error: LoadingError,
  delay: 0,
  timeout: 60000
});
