<template>
  <input
    v-if="editing"
    v-focus
    :value="value"
    type="text"
    class="title-input"
    @blur="onBlur"
    @keydown.enter="onEnter"
    @keydown.esc="onEsc"
  />
  <span v-else class="title-stage" @click="editing = true">
    {{ value || "未命名" }}
    <slot></slot>
  </span>
</template>

<script>
import { focus } from "@/utils/directives";

export default {
  directives: { focus },
  props: {
    value: String
  },
  data() {
    return {
      editing: false
    };
  },
  methods: {
    focus() {
      this.editing = true;
    },
    onBlur(evt) {
      const newval = evt.target.value.trim();
      if (newval && newval !== this.value) {
        this.$emit("input", newval);
        this.$emit("change", newval);
      }
      this.editing = false;
    },
    onEnter(evt) {
      evt.target.blur();
    },
    onEsc(evt) {
      evt.target.value = this.value;
      this.onEnter();
    }
  }
};
</script>

<style lang="less" scoped>
.title-stage,
.title-input {
  font-size: 24px;
  padding: 0 8px;
}
.title-stage {
  min-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-input {
  border: none;
  outline: none;
  width: 300px;
  max-width: 100%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.06);
}
</style>
