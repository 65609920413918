import throttle from "lodash/throttle";
import debounce from "lodash/debounce";

/**
 * 拖拽功能辅助类
 */
class DD {
  constructor() {
    /**
     * @type {Array} 和可拖拽元素对应数据列表
     */
    this._dataSources = null;

    /**
     * @type {Function} 因为拖拽导致位置发生改变的回调函数，会把重新排次序的 _dataSources 当作第一个参数
     */
    this._changeFunc = null;

    /**
     * @type {Function} dragover 事件发生时，节流调用的计算函数
     */
    this._throttled = null;

    /**
     * @type {Function} dragend 事件防抖
     */
    this._debounced = null;

    /**
     * @type {HTMLElement} 接受拖拽元素的容器元素
     */
    this._wrapper = null;

    /**
     * @type {Array<HTMLElement>} 可拖拽元素列表
     */
    this._drags = null;

    /**
     * @type {HTMLElement} 当前拖拽的元素
     */
    this._currentDragElm = null;

    /**
     * @type {Event} 一个事件对象，用于计算鼠标移动了多少距离，然后判断是否移位
     */
    this._MouseEvent = null;

    /**
     * @type {Boolean} 是否有元素正在移位
     */
    this._moveing = null;

    /**
     * @type {Function} 当元素被拖拽的时候，拖拽到左右两边的时候会触发的函数，可以进行自定义的滚动，会把 左 还是 右 当作第一个参数
     */
    this._moveLR = function() {};
  }

  /**
   * 初始化
   */
  init(config) {
    const { dataSources, change, moveLR, wrapper, drags } = config;

    // 初始化之前，先回收上一次初始化的资源
    this.destroyDrag();

    this._dataSources = dataSources;
    this._changeFunc = change;
    this._moveLR = moveLR;

    this._throttled = throttle(event => {
      // 有元素在移动，不允许调用计算函数
      if (this._moveing) return;
      this._moveing = true;
      this.calcPosition(event);
      this._moveing = false;
    }, DD.ANIMATION_TIME);

    this._debounced = debounce(this.handleEnd, DD.ANIMATION_TIME);
    this._wrapper = wrapper;
    this._wrapper.addEventListener("dragover", this.handleDragover);
    this._wrapper.addEventListener("dragend", this._debounced);
    // 禁用 img 的默认可拖拽行为
    const imgList = this._wrapper.getElementsByTagName("img") || [];
    Array.from(imgList).forEach(img => img.setAttribute("draggable", false));

    const tempDrags = [];
    drags.forEach((vnode, index) => {
      const elm = vnode.elm;
      elm.setAttribute("draggable", true);
      elm.setAttribute("role", DD.ROLE_DRAG);
      elm.setAttribute("data-oldindex", index);
      elm.addEventListener("dragstart", this.handleDragstart);
      tempDrags.push(elm);
    });
    this._drags = tempDrags;
  }

  /**
   * 销毁资源回收
   */
  destroyDrag() {
    if (this._wrapper) {
      this._wrapper.removeEventListener("dragover", this.handleDragover);
      this._wrapper.removeEventListener("dragend", this._debounced);
    }
    if (this._drags) {
      this._drags.forEach(elm => {
        elm.removeAttribute("draggable");
        elm.removeAttribute("role");
        elm.removeAttribute("data-oldindex");
        elm.removeEventListener("dragstart", this.handleDragstart);
      });
    }
  }

  /**
   * 拖拽事件监听
   */
  handleDragstart = event => {
    event.dataTransfer.effectAllowed = "move";
    this._currentDragElm = DD.getDragElm(event.target);
  };
  handleDragover = event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    this._throttled(event);
    this.move(event);
  };
  handleEnd = () => {
    this._MouseEvent = null;

    let hasChange = false;
    const oldValue = this._dataSources;
    const newValue = [];
    const children = Array.from(this._wrapper.children || []);
    children.forEach((elm, index) => {
      const oldIndex = parseInt(elm.getAttribute("data-oldindex"));
      if (oldIndex !== index) {
        hasChange = true;
      }
      newValue[index] = oldValue[oldIndex];
    });
    if (hasChange) {
      this._changeFunc(newValue);
    }
  };

  /**
   * 计算并交换元素位置
   */
  calcPosition(event) {
    // 拾起鼠标后，如果移动的距离不够 DD.MOUSE_MOVE 大，那就不做操作
    if (!this._MouseEvent) this._MouseEvent = event;
    const diff = Math.abs(this._MouseEvent.screenX - event.screenX);
    if (diff < DD.MOUSE_MOVE) return;
    this._MouseEvent = event;

    // 如果不存在要换位置的元素，则不做操作
    const elmDrag = this._currentDragElm;
    const elmDown = DD.getDragElm(event.target);
    if (!elmDown || elmDown === elmDrag) return;

    const posotion = elmDrag.compareDocumentPosition(elmDown);
    // elmDrag 前移
    if (posotion === Node.DOCUMENT_POSITION_PRECEDING) {
      DD.startAnimation(this._drags, elmDrag, elmDown, 1, () => {
        this._wrapper.insertBefore(elmDrag, elmDown);
      });
    }
    // elmDrag 后移
    if (posotion === Node.DOCUMENT_POSITION_FOLLOWING) {
      DD.startAnimation(this._drags, elmDrag, elmDown, -1, () => {
        DD.insertAfter(elmDrag, elmDown);
      });
    }
  }

  /**
   * 拖拽到左边、右边
   */
  move(event) {
    const { clientX } = event;
    const { width, left } = this._wrapper.parentNode.getBoundingClientRect();
    let diffLeft = clientX - left;
    let diffRight = clientX - left - width;
    if (diffLeft > 0 && diffLeft < 50) {
      this._moveLR(DD.LEFT);
    }
    if (diffRight < 0 && diffRight > -50) {
      this._moveLR(DD.RIGHT);
    }
  }

  /**
   * 常量
   */
  static ROLE_DRAG = "dragger"; // 可拖拽元素的角色名称
  static ANIMATION_TIME = 200; // 动画时间，单位 ms，必须是数值
  static MOUSE_MOVE = 20; // // 鼠标至少移动多少距离才会触发移位，单位 px，必须是数值
  static LEFT = "left"; // 左边标识
  static RIGHT = "right"; // 右边标识

  /**
   * 辅助函数
   */
  /**
   * 目标元素后面插入新元素
   */
  static insertAfter(newElement, targetElement) {
    const parent = targetElement.parentNode;
    if (parent.lastChild == targetElement) {
      parent.appendChild(newElement);
    } else {
      parent.insertBefore(newElement, targetElement.nextSibling);
    }
  }
  /**
   * 对于某一个节点，获取它祖先上可以拖拽的那个父节点，如果节点本身就可以拖拽那就返回本身
   */
  static getDragElm(node) {
    if (!node || !node.getAttribute) return null;
    if (node.getAttribute("role") === DD.ROLE_DRAG) return node;
    return DD.getDragElm(node.parentNode);
  }
  /**
   * 传入一组兄弟元素，获取其中一个元素左边或者右边的全部兄弟元素
   * 元素位置的比较方法 compareDocumentPosition 可查阅：https://developer.mozilla.org/zh-CN/docs/Web/API/Node/compareDocumentPosition
   */
  static getElms(nodeList = [], node, mode = DD.LEFT) {
    return nodeList.filter(elm => {
      const posotion = node.compareDocumentPosition(elm);
      return (
        (mode === DD.LEFT && posotion === Node.DOCUMENT_POSITION_PRECEDING) ||
        (mode === DD.RIGHT && posotion === Node.DOCUMENT_POSITION_FOLLOWING)
      );
    });
  }
  /**
   * 对传入的两个兄弟元素，区分哪个在前哪个在后
   */
  static sortElm(node1, node2) {
    const posotion = node1.compareDocumentPosition(node2);
    if (posotion === Node.DOCUMENT_POSITION_PRECEDING) {
      return { prev: node2, next: node1 };
    }
    if (posotion === Node.DOCUMENT_POSITION_FOLLOWING) {
      return { prev: node1, next: node2 };
    }
    return {};
  }
  /**
   * 获取元素的真实 width、height，包括 offsetWidth、offsetHeight、margin
   * 为了尽量避免回流重绘的性能问题，除了第一次外会把结果缓存在 node 上
   */
  static getElmSize(node) {
    if (!node || !node.getAttribute) return { width: 0, height: 0 };

    let width = Number(node.getAttribute("data-width"));
    let height = Number(node.getAttribute("data-height"));
    if (!width || !height) {
      const { offsetWidth = 0, offsetHeight = 0 } = node;
      const {
        marginBottom,
        marginLeft,
        marginRight,
        marginTop
      } = window.getComputedStyle(node);
      width = offsetWidth + parseFloat(marginLeft) + parseFloat(marginRight);
      height = offsetHeight + parseFloat(marginTop) + parseFloat(marginBottom);
      node.setAttribute("data-width", width);
      node.setAttribute("data-height", height);
    }
    return { width, height };
  }
  /**
   * 设置动画
   */
  static setAnimation(node, offset = 0, time = 0) {
    if (!node || !node.style) return;
    node.style.transform = `translateX(${offset}px)`;
    node.style.transition = `transform ${time}ms ease-in-out`;
  }
  /**
   * 开始拖拽动画
   * direction 1向前拖拽 -1向后拖拽
   */
  static startAnimation(elms, sourceElm, targetElm, direction = -1, callback) {
    // 获取除 sourceElm 外，需要移动的元素列表
    const { prev, next } = DD.sortElm(sourceElm, targetElm);
    const moveList = DD.getElms(DD.getElms(elms, prev, DD.RIGHT), next).concat([
      targetElm
    ]);

    // 开始动画
    let totalWidth = 0;
    const { width } = DD.getElmSize(sourceElm).width;
    moveList.forEach(elm => {
      DD.setAnimation(elm, width * direction, DD.ANIMATION_TIME);
      totalWidth += DD.getElmSize(elm).width;
    });
    DD.setAnimation(sourceElm, -totalWidth * direction, DD.ANIMATION_TIME);

    // 关闭动画（并调用回调开始后续操作）
    setTimeout(() => {
      moveList.forEach(elm => DD.setAnimation(elm));
      DD.setAnimation(sourceElm);
      if (callback) callback();
    }, DD.ANIMATION_TIME);
  }
}

const dd = new DD();

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(list) {
        if (list && this.disabled) this.$nextTick(this.initDrag);
      }
    },
    disabled: {
      immediate: true,
      handler: function() {
        if (!this.disabled) this.dd.destroyDrag();
      }
    }
  },
  data() {
    return { dd: dd };
  },
  methods: {
    initDrag() {
      dd.init({
        wrapper: this.$refs.wrapper,
        drags: this.$slots.default,
        dataSources: this.value,
        change: newValue => {
          this.$emit("input", newValue);
        },
        moveLR: tag => {
          if (tag === DD.LEFT) {
            this.scroll(-30);
          } else {
            this.scroll(30);
          }
        }
      });
    }
  }
};
