import contextify from "@/components/contextify";

/**
 * 选择列
 * @typedef {{
 *    source: MouseEvent | Element,
 *    inverse: boolean | { x: boolean, y: boolean}
 * }} ContextOptions
 *
 * @param {ContextOptions} args
 */
export default function(args) {
  const opts = {
    ...args,
    inverse: { x: true, y: false },
    style: { overflow: "visible" }
  };
  return contextify(
    () => import(/* webpackChunkName: "spreadsheet" */ "./src/SelectColumn"),
    opts
  );
}
