<template>
  <div class="pane-wrapper">
    <!-- 头部 -->
    <header class="pane-header">
      <!-- tab button -->
      <SwitchPane
        :value="activePane"
        class="ndl-flex-item--grow"
        @input="switchPane"
      />
      <!-- close button -->
      <a class="ndl-color-gray ndl-margin-right" @click="switchPane()">
        <i class="ndl-icon-x"></i>
      </a>
    </header>
    <div class="pane-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import SwitchPane from "./SwitchPane.vue";

export default {
  components: { SwitchPane },
  computed: {
    activePane() {
      return workspace.activePane;
    }
  },
  methods: {
    switchPane(key = null) {
      workspace.activePane = key;
    }
  }
};
</script>

<style lang="less" scoped>
.pane-wrapper {
  display: flex;
  flex-direction: column;
}
.pane-header {
  flex-shrink: 0;
  // height: 46px;
  display: flex;
  align-items: center;
}
.pane-body {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
</style>
