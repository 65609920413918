import contextify from "@/components/contextify";

export default function(args) {
  const opts = {
    ...args,
    inverse: { x: true, y: false }
  };
  return contextify(
    () => import(/* webpackChunkName: "spreadsheet" */ "./src/Pane"),
    opts
  );
}
