<template>
  <Focusable
    size="large"
    :class="['tabitem', { active: isActive }]"
    @click="onClick"
    @contextmenu.native.prevent="onContextmenu"
  >
    <Iconfont :type="node.icon" />
    <span class="label" :title="node.nodeName">{{ node.nodeName }}</span>
  </Focusable>
</template>

<script>
import showContextmenu from "@/components/contextmenu";
import Iconfont from "@/components/iconfont";
import Focusable from "../../focusable";
import { ModelTree } from "../../tree";
import getOptions from "./node-options";

export default {
  components: { Iconfont, Focusable },
  props: {
    node: ModelTree,
    isActive: Boolean
  },
  inject: {
    scrollable: { default: null }
  },
  mounted() {
    if (this.isActive) {
      this.scrollIntoView();
    }
  },
  watch: {
    isActive(bool) {
      if (bool) {
        this.scrollIntoView();
      }
    }
  },
  methods: {
    onClick(evt) {
      if (this.isActive) {
        this.onContextmenu(evt);
      } else {
        this.$emit("select");
      }
    },
    onContextmenu() {
      const contextConfig = {
        source: this.$el,
        inverse: { y: false, x: true }
      };
      showContextmenu(
        { options: getOptions(this.node), width: "180px" },
        contextConfig
      );
    },
    scrollIntoView() {
      const scrollable = this.scrollable;
      if (!scrollable) {
        return;
      }
      scrollable.scrollIntoView(this.$el);
    }
  }
};
</script>
