import isEqualWith from "lodash/isEqualWith";
/**
 * debounce function by requestAnimationFrame
 * @param {Function} fn
 * @returns {Function} debounced function
 */
export function debounceByAnimationFrame(fn) {
  let args = [];
  let handle = null;
  function debouncedFn() {
    args = arguments;
    cancelAnimationFrame(handle);
    handle = requestAnimationFrame(() => {
      fn.apply(this, args);
    });
  }
  return debouncedFn;
}

export function throttleByAnimationFrame(fn) {
  let args = [];
  let flag = false;
  function throttledFn() {
    args = arguments;
    if (flag) return;
    flag = true;
    requestAnimationFrame(() => {
      fn.apply(this, args);
      flag = false;
    });
  }
  return throttledFn;
}

/**
 * 根据 lodash isEqualWith 判断是否相同
 * 但 null 和 undefined 会被认为是相等的
 * @param {*} value The value to compare.
 * @param {*} other The other value to compare.
 * @return {Boolean}
 */
export function isEqual(value, other) {
  function customizer(a, b) {
    if (a === null || b === null) return a == b;
  }
  return isEqualWith(value, other, customizer);
}
