<template>
  <a-tooltip placement="bottom" :title="showLabel ? null : label">
    <span
      tabindex="1"
      :class="[
        'tool-btn',
        { disabled, active, highlight, 'with-label': label && showLabel }
      ]"
      @click="onClick"
    >
      <i :class="[icon, 'btn-icon']"></i>
      <span v-if="label && showLabel" class="btn-label">
        {{ label }}
      </span>
    </span>
  </a-tooltip>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    active: Boolean,
    highlight: Boolean,
    showLabel: {
      type: Boolean,
      default: true
    },
    label: String,
    icon: String
  },
  methods: {
    onClick(evt) {
      if (!this.disabled) {
        this.$emit("click", evt);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

@size: 24px;
.tool-btn {
  height: @size;
  width: @size;
  user-select: none;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), -2px -2px 10px rgba(0, 0, 0, 0.06);
  outline: none;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }

  &.active,
  &:not(.disabled):active {
    background: @primary-color-light;
  }
  &.highlight {
    background: @blue-6;
    color: #fff;
  }
  &.highlight:active {
    background: @blue-5;
  }

  &.disabled {
    cursor: not-allowed;
    color: @disabled-color;
  }

  // icon 和 label 不作为 event.target，便于定位弹出的 context 位置
  .btn-icon {
    font-size: 13px;
    pointer-events: none;
  }
  .btn-label {
    font-size: 12px;
    pointer-events: none;
  }

  &.with-label {
    width: auto;
    padding: 0 8px;
  }
  &.with-label .btn-icon {
    margin-right: 6px;
  }
}
</style>
