<template>
  <div>
    <div
      v-for="item in tabs"
      :key="item[0]"
      :class="['light-btn', { active: value === item[0] }]"
      @click="$emit('input', item[0])"
    >
      <i :class="`ndl-icon-${item[1]}`"></i>
      <span>{{ item[2] }}</span>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: {
    value: String
  },
  data() {
    const tabs = [];

    tabs.push(["kinship", "trello", "血缘"]); // 血缘 暂不进行权限控制

    tabs.push(["materialize", "cpu", "物化"]); // 物化 暂不进行权限控制

    if (store.isModuleEnabled("datamodel.api")) {
      tabs.push(["api", "apps-line", "API"]);
    }
    if (store.isModuleEnabled("datamodel.schedule")) {
      tabs.push(["schedule", "git-pull-request", "推送"]);
    }
    return { tabs };
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/app.less";

.light-btn {
  height: 46px;
  padding: 0 12px;
  cursor: pointer;
  transition: color 0.2s;
  border-bottom: solid 2px transparent;
  display: inline-flex;
  align-items: center;
  &:hover,
  &.active {
    color: @blue-6;
  }
  &.active {
    border-color: @blue-5;
  }
  span {
    margin-left: 5px;
    font-size: 12px;
  }
}
</style>
