<template>
  <NodeHeader :node-id="$route.params.workspaceId" />
</template>

<script>
import NodeHeader from "./NodeHeader";

export default {
  components: { NodeHeader }
};
</script>
