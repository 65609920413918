<template>
  <Pane :style="{ width: paneWidth }">
    <div class="loading">
      <a-icon type="loading" class="loading-icon" />
    </div>
  </Pane>
</template>

<script>
import Pane from "../../pane-container";
import { PANE_WIDTH } from "./constant";
export default {
  components: { Pane },
  data() {
    return { paneWidth: PANE_WIDTH };
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-icon {
    color: @blue-6;
    font-size: 36px;
  }
}
</style>
