<template>
  <div class="header">
    <Focusable size="large" class="header-icon" @click="onIconClick">
      <Iconfont :type="node.icon" />
    </Focusable>
    <div class="header-title">
      <NodeTitle ref="title" :value="node.nodeName" @change="onTitleChange" />
    </div>
    <div class="header-actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import Iconfont from "@/components/iconfont";
import Focusable from "../../focusable";
import NodeTitle from "./NodeTitle";

export default {
  components: { Iconfont, Focusable, NodeTitle },
  props: {
    nodeId: String
  },
  computed: {
    node() {
      return workspace.getNodeById(this.nodeId) || {};
    },
    editable() {
      const permissions = this.node.permissions || {};
      return !permissions.readonly;
    }
  },
  mounted() {
    this.checkRenamingStatus();
  },
  watch: {
    nodeId() {
      this.checkRenamingStatus();
    }
  },
  methods: {
    checkRenamingStatus() {
      const nodeStatus = this.node.nodeStatus || {};
      if (nodeStatus.renamingAtHeader) {
        this.$refs.title.focus();
      }
      nodeStatus.renamingAtHeader = false;
    },
    onIconClick(evt) {
      if (this.node.setIcon) {
        this.node.setIcon(evt.target);
      }
    },
    onTitleChange(value) {
      if (this.node.setName) {
        this.node.setName(value);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.header {
  padding: 28px @workspace-edge;
  display: flex;
  align-items: center;
  .header-icon {
    @size: 36px;
    width: @size;
    height: @size;
    font-size: @size;
    margin-right: 6px;
    flex-shrink: 0;
  }
  .header-title {
    flex: 1 1 0;
  }
  .header-actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}
</style>
