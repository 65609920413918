import router from "@/router";
import workspace from "@/views/datamodel/models/workspace";
import { Modal } from "ant-design-vue";
import { ContextOption } from "@/components/contextmenu";
import { gotoWorkspace } from "../../aside/src/node-options";

/**
 * @param {import("@/views/datamodel/models/workspace").Workspace} node
 */
export default function(node) {
  const permissions = node.permissions;
  return [
    new ContextOption("rename", "重命名", "edit-line")
      .$disabled(permissions.readonly)
      .$listener(function() {
        node.nodeStatus.renamingAtContext = true;
        // 触发视图列表元素的 click 事件，在弹出的 context 中进行编辑
        // 依赖于列表按钮的 data-id-list 属性
        const elm = document.querySelector(".tabs .focusable[data-id-list]");
        if (elm) {
          elm.dispatchEvent(new Event("click"));
        }
      }),
    new ContextOption("copy", "复制一份", "copy")
      .$disabled(!permissions.manageable)
      .$listener(async function() {
        const clonedNode = await node.clone();
        gotoWorkspace(clonedNode);
      }),
    new ContextOption("export-csv", "导出为 CSV", "file-text-line", true)
      .$disabled(permissions.readonly)
      .$listener(function() {
        node.exportCSV();
      }),
    new ContextOption("delete", "删除此视图", "trash-2")
      .$iconClass("ndl-color-red")
      .$labelClass("ndl-color-red")
      // 无管理权限的 node 禁用删除
      .$disabled(!permissions.manageable)
      .$listener(function() {
        Modal.confirm({
          title: "删除视图",
          content: `确定要删除「${node.nodeName}」吗？删除操作无法撤销。`,
          okType: "danger",
          onOk: async () => {
            await node.remove();
            // 删除的是当前 sheet 时，自动切换到第一个 datasheet
            if (node.nodeId === router.currentRoute.params.datasheetId) {
              gotoWorkspace(workspace.currentWorkspace);
            }
          }
        });
      })
  ];
}
