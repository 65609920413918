<template>
  <li
    :key="node.nodeId"
    :class="['list-item', { active: isActive }]"
    :title="node.nodeName"
    @click="onSelect"
  >
    <i class="item-drag ndl-icon-move-drag"></i>
    <Focusable class="item-icon" @click="onIconClick">
      <Iconfont :type="node.icon" />
    </Focusable>
    <input
      v-if="!permissions.readonly && editing"
      v-focus
      :value="node.nodeName"
      :placeholder="node.nodeName"
      class="item-title-input"
      @keydown.enter="onTitleEnter"
      @keydown.esc="onTitleEsc"
      @blur="onTitleBlur"
    />
    <span v-else class="item-title">
      {{ node.nodeName }}
    </span>
    <Focusable
      v-if="!permissions.readonly"
      class="item-action"
      @click="editing = true"
    >
      <i class="ndl-icon-edit-line"></i>
    </Focusable>
    <Focusable
      v-if="permissions.manageable"
      class="item-action ndl-color-red"
      @click="onRemove()"
    >
      <i class="ndl-icon-close-circle-fill"></i>
    </Focusable>
  </li>
</template>

<script>
import { focus } from "@/utils/directives";
import Iconfont from "@/components/iconfont";
import { ModelTree } from "../../tree";
import Focusable from "../../focusable";

export default {
  components: { Focusable, Iconfont },
  directives: { focus },
  props: {
    node: ModelTree,
    isActive: Boolean
  },
  data() {
    const nodeStatus = this.node.nodeStatus;
    const editing = !!nodeStatus.renamingAtContext;
    nodeStatus.renamingAtContext = false;
    return { editing };
  },
  computed: {
    permissions() {
      return this.node.permissions || {};
    }
  },
  methods: {
    onSelect() {
      if (!this.editing) {
        this.$emit("select");
      }
    },
    onIconClick(evt) {
      if (this.node.setIcon) {
        this.node.setIcon(evt.target);
      }
    },
    onRemove() {
      this.$confirm({
        title: "删除视图",
        content: `确认要删除视图「${this.node.nodeName}」?删除操作无法撤销。`,
        okType: "danger",
        onOk: () => this.node.remove()
      });
    },
    onTitleEnter(evt) {
      evt.target.blur();
    },
    onTitleEsc(evt) {
      evt.target.value = this.node.nodeName;
      evt.target.blur();
    },
    onTitleBlur(evt) {
      this.node.setName(evt.target.value);
      evt.target.value = this.node.nodeName;
      this.editing = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.list-item {
  display: flex;
  align-items: center;
  width: 300px;
  height: 42px;
  border-radius: 4px;
  padding: 0 4px;
  cursor: pointer;

  &:hover,
  &.active {
    background: rgba(240, 240, 240, 0.65);
  }
  & + & {
    margin-top: 8px;
  }
  .item-drag {
    color: #bfbfbf;
    cursor: move;
    margin-right: 4px;
  }
  .item-icon {
    font-size: 18px;
    flex-shrink: 0;
  }
  .item-title-input {
    flex: 1 1 0;
    border: none;
    outline: none;
    border-radius: 3px;
    margin: 0 2px;
    padding: 4px 6px;
    background: rgba(0, 0, 0, 0.06);
  }
  .item-title {
    flex: 1 1 0;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 2px;
  }
  &:hover .item-title {
    opacity: 0.75;
  }
  .item-action {
    flex-shrink: 0;
    visibility: hidden;
  }
  &:hover .item-action {
    visibility: visible;
  }
  &.sortable-chosen {
    background: #fff;
  }
  &.sortable-chosen .item-action {
    visibility: hidden;
  }
}
</style>
