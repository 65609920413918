import { render, staticRenderFns } from "./ContextItem.vue?vue&type=template&id=c25d5cfc&scoped=true&"
import script from "./ContextItem.vue?vue&type=script&lang=js&"
export * from "./ContextItem.vue?vue&type=script&lang=js&"
import style0 from "./ContextItem.vue?vue&type=style&index=0&id=c25d5cfc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c25d5cfc",
  null
  
)

export default component.exports