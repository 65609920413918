import load from "../pane-loading";
import { PANE_WIDTH } from "./src/constant";

const { Loading, LoadingError } = load(PANE_WIDTH);
export default () => ({
  component: import(/* webpackChunkName: "pane-kinship" */ "./src/Kinship.vue"),
  loading: Loading,
  error: LoadingError,
  delay: 0,
  timeout: 60000
});
