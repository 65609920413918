<template>
  <div class="tabs">
    <Scrollable
      v-if="sheets.length"
      wrapper-class="tablist"
      v-model="sheets"
      :disabled="disabled"
    >
      <TabItem
        v-for="item in sheets"
        :key="item.nodeId"
        :node="item"
        :is-active="activeDatasheetId === item.nodeId"
        @select="onDatasheetChange(item)"
      />
    </Scrollable>
    <Focusable
      v-if="!permissions.readonly"
      id="tabs-add-item"
      size="large"
      :class="['tabitem', 'noshrink', { active: focusOnAddButton }]"
      @click="onDatasheetAdd"
    >
      <i class="ndl-icon-plus"></i>
      <span class="label">新建视图</span>
    </Focusable>
    <!-- ⚠️ data-id-list 属性用于 tab 右键菜单重命名时查找元素，勿移除 -->
    <Focusable
      v-if="sheets.length"
      size="large"
      class="noshrink"
      data-id-list
      @click="showDatasheetList"
    >
      <i class="ndl-icon-list"></i>
    </Focusable>
    <!-- 占位符，吸收剩余空间 -->
    <span class="tabs-placeholder"></span>
    <Focusable
      v-if="showKinshipButton"
      size="large"
      :class="['tabitem', 'noshrink']"
      @click="onPaneActive('kinship')"
    >
      <i class="ndl-icon-trello"></i>
      <span class="label">血缘</span>
    </Focusable>
    <Focusable
      v-if="showMaterializeButton"
      size="large"
      :class="['tabitem', 'noshrink']"
      @click="onPaneActive('materialize')"
    >
      <i class="ndl-icon-cpu"></i>
      <span class="label">物化</span>
    </Focusable>
    <Focusable
      v-if="showAPIButton"
      size="large"
      :class="['tabitem', 'noshrink']"
      @click="onPaneActive('api')"
    >
      <i class="ndl-icon-apps-line"></i>
      <span class="label">API</span>
    </Focusable>
    <Focusable
      v-if="showScheduleButton"
      size="large"
      :class="['tabitem', 'noshrink']"
      @click="onPaneActive('schedule')"
    >
      <i class="ndl-icon-git-pull-request"></i>
      <span class="label">推送</span>
    </Focusable>
  </div>
</template>

<script>
import store, { ui as uiState } from "@/store";
import workspace from "@/views/datamodel/models/workspace";
import contextify from "@/components/contextify";
import Scrollable from "./Scrollable";
import Focusable from "../../focusable";
import TabItem from "./TabItem";
import Context from "./Context";

export default {
  components: { Scrollable, Focusable, TabItem },
  props: {
    focusOnAddButton: Boolean
  },
  computed: {
    workspaceNode() {
      return workspace.getNodeById(this.$route.params.workspaceId);
    },
    permissions() {
      const permissions = this.workspaceNode
        ? this.workspaceNode.permissions
        : {};
      return permissions;
    },
    sheets: {
      get() {
        const node = workspace.getNodeById(this.$route.params.workspaceId);
        const children = node ? node.children : [];
        return children.filter(child => child.isDatasheet);
      },
      set(children) {
        const node = workspace.getNodeById(this.$route.params.workspaceId);
        if (node && node.children) {
          node.updateChildrenPosition(children);
        }
      }
    },
    disabled() {
      const node = workspace.getNodeById(this.$route.params.workspaceId);
      const permissions = node.permissions || { manageable: false };
      return permissions.manageable;
    },
    activeDatasheetId() {
      return this.$route.params.datasheetId;
    },
    showKinshipButton() {
      return !workspace.activePane;
    },
    showMaterializeButton() {
      return !workspace.activePane;
    },
    showAPIButton() {
      return !workspace.activePane && store.isModuleEnabled("datamodel.api");
    },
    showScheduleButton() {
      return (
        !workspace.activePane && store.isModuleEnabled("datamodel.schedule")
      );
    }
  },
  methods: {
    onDatasheetChange({ nodeId: datasheetId }) {
      const params = Object.assign({}, this.$route.params, { datasheetId });
      this.$router.replace({ name: "datamodel-workspace", params });
    },
    onDatasheetAdd() {
      const params = { workspaceId: this.$route.params.workspaceId };
      this.$router.replace({ name: "datamodel-workspace-guide", params });
    },
    showDatasheetList(evt) {
      contextify(Context, {
        ...this.$route.params,
        source: evt.target,
        light: true
      });
    },
    onPaneActive(pane) {
      workspace.activePane = pane;
      uiState.float = true;
      uiState.visible = false;
    }
  }
};
</script>

<style lang="less" scoped src="./style.less"></style>
