import contextify from "@/components/contextify";

export default function(args) {
  const opts = {
    ...args,
    inverse: { x: true, y: false },
    style: { overflow: "visible" }
  };
  return contextify(
    () => import(/* webpackChunkName: "spreadsheet" */ "./src/Filter"),
    opts
  );
}
