<template>
  <div class="page-wrapper">
    <div class="main-content">
      <template v-if="currentNode.isDatasheet">
        <Header />
        <Tabs />
        <Toolbar />
        <Datasheet class="body" />
      </template>
      <Link v-else-if="currentNode.isLink" />
      <Dashboard v-else-if="currentNode.isDashboard" />

      <transition name="ndl-fade">
        <div v-show="isLoading" class="ndl-spin"></div>
      </transition>
    </div>
    <component
      v-if="currentNode.isDatasheet"
      v-resizable:left
      :is="activePane"
      class="side-content"
    />
  </div>
</template>

<script>
import { resizable } from "@/utils/directives";
import Header from "./components/workspace-header";
import Tabs from "./components/tabs";
import Toolbar from "./components/toolbar";
import PaneKinship from "./components/pane-kinship";
import PaneMaterialize from "./components/pane-materialize";
import PaneAPI from "./components/pane-api";
import PaneSchedule from "./components/pane-schedule";

import workspace from "./models/workspace";

export default {
  components: {
    Header,
    Tabs,
    Toolbar,
    // eslint-disable-next-line prettier/prettier
    Datasheet: () => import(/* webpackChunkName: "spreadsheet" */ "./components/datasheet"),
    // eslint-disable-next-line prettier/prettier
    Link: () => import(/* webpackChunkName: "link" */ "./components/link-hybrid"),
    // eslint-disable-next-line prettier/prettier
    Dashboard: () => import(/* webpackChunkName: "dashboard" */ "./components/dashboard")
  },
  directives: { resizable },
  computed: {
    activePane() {
      const activePane = workspace.activePane;
      const map = {
        kinship: PaneKinship,
        materialize: PaneMaterialize,
        api: PaneAPI,
        schedule: PaneSchedule
      };
      return map[activePane] || null;
    },
    currentNode() {
      return workspace.getNodeById(workspace.currentDatasheetId) || {};
    },
    isLoading() {
      if (workspace.nodeStatus.loading) {
        return true;
      }
      const nodeStatus = this.currentNode.nodeStatus || {};
      return nodeStatus.loading;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.page-wrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
  .main-content {
    flex: 1 1 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .header,
  .tabs,
  .toolbar {
    flex-shrink: 0;
  }
  .body {
    flex: 1 1 0;
    height: 0;
  }
  .side-content {
    min-width: 400px;
    width: 430px;
    max-width: 1000px;
    flex-shrink: 0;
    border-left: solid 1px #ebebeb;
    position: relative;
  }
}
.ndl-spin {
  position: absolute;
  z-index: @datasheet-loading-mask-zindex;
  // 这个值是 header + tabs 的高度...在浏览器中找来的...
  top: 127px;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: progress;
  background-color: rgba(255, 255, 255, 0.35);
  background-image: url(~@/assets/images/loading-bars.svg);
  background-repeat: no-repeat;
  background-position: center;
}
</style>
